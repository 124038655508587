import React from "react";
import HeaderSection from "../../components/header";
import promotionImg from "../../assets/promotioImg.jpeg"
import HeroSection from "../../components/herosection";
import Footer from "../../components/footerSection";
import call1 from "../../assets/callYellow.svg";
import gas from "../../assets/gasimage.png";
import pellet from "../../assets/pelletimage.png";
import wood from "../../assets/woodimage.png";
import electric from "../../assets/electricimage.png";
import gasstove from "../../assets/gasstove.png";
import "./sales.css";
import star from "../../assets/star_yellow.svg";
import ContactUsSection from "../../components/servicesSection/ContactUsSection";
import ContactUsSales from "./ContactUsSales";
import FaqComponent from "../faq/FAQPage/FaqComponent";
import { listOfFaq } from "../../components/listofData";
import sales1 from "../../assets/sales1.jpg";
import sales2 from "../../assets/sales2.jpg";
import sales3 from "../../assets/sales3.jpg";
import sales4 from "../../assets/sales4.jpg";
import sales5 from "../../assets/sales5.jpg";
import sales6 from "../../assets/sales6.jpg";
import sales7 from "../../assets/sales7.jpg";



function Promotions() {

  let details = [{
    title:"AMANTII SYM 34 SMART ELECTRIC FIREPLACE",
    describe:"",
    retailPrice:"$1699.00 + GST",
    salePrices:"$899.00 + GST",
    image:sales1,
    
  },{
    title:"SIERRA FLAME ZERO CLEARANCE ELECTRIC FIREPLACE",
    describe:"",
    retailPrice:"$499.00 + GST",
    salePrices:"$150.00 + GST",
    image:sales2
  },{
    title:"AMANTII PANORAMA BI130 ELECTRIC FIREPLACE",
    describe:"",
    retailPrice:"$1199.00 + GST",
    salePrices:"$999.00 + GST",
    image:sales3
  },{
    title:"SIERRA FLAME 26 LINEAR ELECTRIC FIREPLACE",
    describe:"",
    retailPrice:"$1099.00 + GST",
    salePrices:"$699.00 + GST",
    image:sales4
  },{
    title:"2017 REGENCY U38 B VENT GAS STOVE",
    describe:"",
    retailPrice:"$5111.00 + GST",
    salePrices:"$2915.00 + GST",
    image:sales5
  },{
    title:"2019 QUADRA FIRE MT. VERNON TREKKER",
    describe:"",
    retailPrice:"$7999.00 + GST",
    salePrices:"$4999.00 + GST",
    image:sales6
  },{
    title:"2020 BRAXTON WALNUT MANTEL",
    describe:"",
    retailPrice:"$1928.00 + GST",
    salePrices:"$1199.00 + GST",
    image:sales7

  }

]

  return (
    <div className="Sales">
      <HeaderSection />
      <HeroSection />
      {/* <div className="product-container">
        {[
          { image: gas, name: "Gas" },
          { image: pellet, name: "Pellet" },
          { image: wood, name: "Wood" },
          { image: electric, name: "Electric" },
        ].map((item) => (
          <div className="product-item ">
            <img src={item?.image} alt="" className="product-image" />
            <p className="product-name">{item?.name}</p>
          </div>
        ))}
      </div> */}
     <div className="promotionPageImgDiv">
      <img src={promotionImg} alt="promotioImg"/>
     </div>
      <section className="same_width_container ">
        <div className="sales_all_box_container">
          {details.map((i,index) => {
            return (
              <div className="sales_self_box shadow-lg mt-10" key={index}>
                <div className="img_stove_container">
                  {/* <div className="sales_text_container">Sale</div> */}
                  <img
                    src={i.image}
                    alt=""
                    className="stove_image shadow-sm"
                  />
                </div>
                <div className="all_gas_content_container">
                  <p className="gas_title_name">
                    {i.title}{" "}
                  </p>
                  {/* <p className="gas_description">
                    Total satisfaction assured. Should any issues arise, we
                    promise prompt resolution, ensuring your peace of mind and
                    lasting satisfaction.
                  </p> */}
                  {/* <p className="rating_star_text">
                    <img src={star} alt="star" loading="lazy" />
                    4.8
                  </p> */}
                  <p className="retails_price_text">
                    Retail Price : <span>{i.retailPrice} </span>
                  </p>
                  <p className="sales_price_title">
                    Sale Price : <span>{i.salePrices}</span>{" "}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      {/* <ContactUsSales /> */}
      <ContactUsSection />
      <FaqComponent
        firstSentence={`Frequently Asked `}
        secSentence={`Questions`}
        thirdSentence={`Sweep - Breathe Easy This Winter.`}
        ExpandDataAll={listOfFaq}
        // faqImg={faqImg}
      />
      <Footer />
    </div>
  );
}

export default Promotions;

// import React from 'react';
// import './sales.css';
// import HeaderSection from '../../components/header';
// import HeroSection from '../../components/herosection';
// import Footer from '../../components/footerSection';
// import call1 from "../../assets/callYellow.svg";
// import gas from '../../assets/gasimage.png';
// import pellet from '../../assets/pelletimage.png';
// import wood from '../../assets/woodimage.png';
// import electric from '../../assets/electricimage.png';
// import gasstove from '../../assets/gasstove.png';

// function Sales() {
//   return (
//     <div className="Sales">
//       <HeaderSection />
//       <HeroSection />
//       <div className='product-container'>
//         {[{image: gas, name: "Gas"}, {image: pellet, name: "Pellet"}, {image: wood, name: "Wood"}, {image: electric, name: "Electric"}].map((item) => (
//           <div className='product-item'>
//             <img src={item?.image} alt={item?.name} className='product-image' />
//             <p className='product-name'>{item?.name}</p>
//           </div>
//         ))}
//       </div>
//       <div className='image-container'>
//         <img src={gasstove} alt="Gas Stove" className='gas-stove-image' />
//       </div>
//       <div className="contact-container">
//         <p>Contact Us At</p>
//         <div className='contact-info'>
//           <img src={call1} alt="Call" className='contact-icon' />
//           <span className='contact-number'>780-986-1181</span>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// }

// export default Sales;
