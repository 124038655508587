import React from "react";
import Dashboard from "./pages/dashBoardPage/Dashboard";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AboutUs from "./pages/aboutusPage/AboutUs";
import ServicePage from "./pages/ServicePage";
import ServicePageWetInsception from "./pages/ServicePage/ServicePageWetInsception";
import ServicePageInstallation from "./pages/ServicePage/ServicePageInstallation";
import Promotions from "./pages/sales/Sales";
import ServicePageGas from "./pages/ServicePage/ServicePageGas";
import ContactUspage from "./pages/contactUsPage/index";
import ServicePagePellet from "./pages/ServicePage/ServicePagePellet";
import ServicePageElectric from "./pages/ServicePage/ServicePageElectric";
import ServicePageWood from "./pages/ServicePage/ServicePageWood";
import ServicePageIMassonary from "./pages/ServicePage/ServicePageMassonary";
import OurGallary from "./pages/OurGallary";
import Faq from './pages/faq'
import JobCard from "./leducJob/JobCard";
import JobDetails from "./leducJob/JobDetails";
import JobPage from "./leducJob/JobPage";

function App() {



  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/ServicePage" element={<ServicePage />} />
          <Route
            path="/ServicePageWetInsception"
            element={<ServicePageWetInsception />}
          />
          <Route
            path="/ServicePageInstallation"
            element={<ServicePageInstallation />}
          />
          <Route path="/promotions" element={<Promotions/>} />
          <Route
            path="/ServicePageMassonary"
            element={<ServicePageIMassonary />}
          />
          <Route path="/ServicePageGas" element={<ServicePageGas />} />
          <Route path="/ServicePagePellet" element={<ServicePagePellet />} />
          <Route
            path="/ServicePageElectric"
            element={<ServicePageElectric />}
          />
          <Route path="/ServicePageWood" element={<ServicePageWood />} />
          <Route path="/ContactUspage" element={<ContactUspage />} />
          <Route path="/Faq" element={<Faq />} />
          <Route path="/OurGallary" element={<OurGallary />} />
          <Route path="/ouropenings" element={<JobPage/>}/>
          <Route path="/jobdetails" element={<JobDetails/>}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
