import React from 'react'
import { RxCross2 } from "react-icons/rx";
const PromotionPopup = ({handleClose}) => {
  return (
    <div className='popupMainDiv'>
      <div className='popupDiv'>
      <span className='crossBtn' onClick={handleClose}>
      <RxCross2 />
      </span>
      </div>
    </div>
  )
}

export default PromotionPopup
